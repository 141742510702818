<template>
  <div class="float_wrap"
       @click="showModelHandle">
    <div class="showIconBg">
      <span class="showIcon">{{showModel ? "-" : "+"}}</span>
    </div>
    <div class="content_box"
         v-if="!showModel">
      <div class="title">
        关于格乐立
        <span class="right_text">®</span>我应该知道的最重要的信息
      </div>

      <div class="content">
        使用前您应该和医生了解格乐立
        <span class="right_text">®</span>的获益和潜在风险。格乐立
        <span class="right_text">®</span>是一种肿瘤坏死因子（TNF）拮抗剂，可降低免疫系统抗感染的能力。如果您有任何类型的感染，在医生认可前不应使用格乐立
        <span class="right_text">®</span>。
      </div>
      <div class="fadeout"></div>
    </div>

    <!-- 弹窗 -->
    <div class="model_wrap"
         @click="(e)=> {e.stopPropagation()}"
         v-else>
      <div class="duty_text"
           id="tips">
        <div class="duty_content duty_content2">
          <div class="title">
            关于格乐立
            <span class="right_text">®</span>我应该知道的最重要的信息
          </div>
          <div class="content">
            使用前您应该和医生了解格乐立
            <span class="right_text">®</span>的获益和潜在风险。格乐立®是一种肿瘤坏死因子（TNF）拮抗剂，可降低免疫系统抗感染的能力。如果您有任何类型的感染，在医生认可前不应使用格乐立
            <span class="right_text">®</span>。
            <div class="cicle_content">
              <div class="circle"></div>
              <strong>严重感染可能</strong>
            </div>
            <div class="cicle_content">
              <p>
                <span style="position: relative; top: -0.2rem;"
                      class="circle"></span>
                <strong>
                  &nbsp;&nbsp;&nbsp;&nbsp;使用格乐立
                  <span class="right_text">®</span>治疗有可能增加严重感染风险，可能导致住院或死亡。这些严重感染包括活动性结核病（包括潜伏性结核感染重新激活）、侵袭性真菌感染、细菌、病毒和由其它条件致病菌导致的感染。
                </strong>
                <span>
                  在开始使用格乐立
                  <span class="right_text">®</span>之前，医生应该检查您是否患有结核病或潜伏性结核可能，包括仔细检查是否有结核病的症状、体征和做相关的结核筛查检测。即使您的结核筛查检测为阴性，如果医生觉得您有风险，可能建议您先接受结核病药物的治疗。在使用格乐立
                  <span class="right_text">®</span>治疗期间和治疗后，您也要定期复查，排除各种严重感染发生的风险。您如果患有慢性或复发性感染，在开始使用格乐立
                  <span class="right_text">®</span>治疗前应综合考虑治疗的潜在风险和获益。
                </span>
              </p>
            </div>
            <div class="cicle_content">
              <div class="circle"></div>
              <strong>恶性肿瘤可能</strong>
            </div>
            <div class="cicle_content">
              <p>
                <span class="circle"></span>&nbsp;&nbsp;&nbsp;&nbsp;对于使用TNF拮抗剂(包括格乐立
                <span class="right_text">®</span>)的儿童和成人来说，患淋巴瘤或其他癌症的几率可能会增加。在使用TNF拮抗剂的儿童、青少年和年轻人中，出现一些不常见的恶性肿瘤病例，如罕见的可能导致死亡的肝脾T细胞淋巴瘤。另外，使用TNF拮抗剂（包括格乐立
                <span class="right_text">®</span>）过程中，患基底细胞癌和鳞状细胞癌的几率可能会增加，但是经治疗这些疾病通常不会危及生命。如果您有肿块（包括良性或恶性肿瘤）或未愈合伤口请告诉医生。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showModel: false
    };
  },
  methods: {
    showModelHandle () {
      this.showModel = !this.showModel;
    }
  }
};
</script>

<style  lang="less" scoped>
.float_wrap {
  .model_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .duty_content2 {
    width: 100% !important;
    padding: 3rem;
  }
  cursor: pointer;
  .showIconBg {
    background: #3554aa;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: absolute;
    right: 2rem;
    top: 1rem;
    cursor: pointer;

    .showIcon {
      font-weight: 700;
      color: #ffffff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      position: relative;
      top: -0.1rem;
    }
  }
  .content_box {
    font-size: 0.875rem;
    font-family: "Museo Sans W01_300";
    font-weight: unset;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
  }
  .fadeout {
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0, #f3f3f3 100%);
    background-image: linear-gradient(rgba(255, 255, 255, 0) 0, #f3f3f3 100%);
    background-image: -ms-linear-gradient(
      rgba(255, 255, 255, 0) 0,
      #f3f3f3 100%
    );
    bottom: 0;
    height: 50px;
    position: absolute;
    right: 0;
    width: 100%;
  }
  .title {
    font-size: 1.2rem;
    font-weight: 500;
    color: #3554aa;
    line-height: 2.5rem;
    .right_text {
      top: -0.7rem;
      left: -0.4rem;
    }
  }
  .content {
    font-size: 0.93rem;
    font-weight: 400;
    color: #606161;
    line-height: 2rem;
    .right_text {
      font-size: 0.7rem;
      top: -0.5rem;
      left: -0.2rem;
    }
    .cicle_content {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;

      p {
        margin: 0;
      }

      .circle {
        display: inline-flex;
        width: 5px;
        height: 5px;
        background: #3554aa;
        border-radius: 100%;
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
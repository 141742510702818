import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/common/responsive.less";
import animated from "animate.css";
import Meta from "vue-meta";
Vue.use(Meta);
Vue.use(animated);
Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#app");

<template>
  <div id="app">
    <!-- 导航 -->
    <nav-bar :activeIndex="0"></nav-bar>
    <div class="banner banner_1">
      <div id="title"
           class="title">
        格乐立
        <span class="right_text">®</span>
        <span class="noblock"></span>—— 首个国产阿达木单抗
      </div>
      <div class="des des2">
        格乐立
        <span class="right_text">®</span>，焕生命活力
        <!-- 中国首个阿达木单抗生物类似药。关键质量属性、临床疗效、安全性与原研药物高度一致。 -->
      </div>
      <!-- <div class="des">中国首个阿达木单抗生物类似药。</div>
      <div class="des">关键质量属性、临床疗效、安全性与原研药物高度一致。</div>-->
      <a href="https://www.nmpa.gov.cn/zhuanti/cxylqx/cxypxx/20191107100901204.html"
         target="_blank"
         class="btn btn2">
        国家药监局
        <span class>（索引号 XZXK-2020-1341）</span>
      </a>
    </div>
    <div class="banner banner_2">
      <div class="title">获批适应症</div>
      <div class="des">
        格乐立
        <span class="right_text">®</span>在国内已获批强直性脊柱炎、类风湿关节炎、银屑病、克罗恩病、儿童克罗恩病、葡萄膜炎、
        儿童斑块状银屑病、多关节型幼年特发性关节炎8个适应症。
      </div>
      <a href="productDescritption.html"
         class="btn">了解更多</a>
    </div>
    <!-- 四宫格 -->
    <div class="reg_box">
      <div class="reg_content">
        <!-- 第一行 -->
        <div class="col col1">
          <div class="row row1"
               @mouseover="addAnimate('icon_head', 'animated bounceIn')"
               @mouseout="removeAnimate('icon_head', 'animated bounceIn')">
            <div id="icon_head"
                 class="icon icon_1"></div>
            <!-- <div class="title">安全性高</div> -->
            <div class="des">
              格乐立
              <span class="right_text">®</span>（阿达木单抗）与原研阿达木单抗临床等效，
              <br />安全性与其他生物制剂无差异
            </div>
          </div>
          <div class="row"
               @mouseover="addAnimate('icon_time', 'animated swing')"
               @mouseout="removeAnimate('icon_time', 'animated swing')">
            <div id="icon_time"
                 class="icon icon_2"></div>
            <!-- <div class="title">快速起效 疗效持久</div> -->
            <div class="des">
              格乐立
              <span class="right_text">®</span>（阿达木单抗）可快速缓解临床症状，
              <br />降低炎性指标；长期治疗，可维持临床缓解
            </div>
          </div>
        </div>
        <!-- 第二行 -->
        <div class="col">
          <div class="row row1"
               @mouseover="addAnimate('icon_inject', 'animated flipInX')"
               @mouseout="removeAnimate('icon_inject', 'animated flipInX')">
            <div id="icon_inject"
                 class="icon icon_3"></div>
            <!-- <div class="title">先进配方</div> -->
            <div class="des">
              格乐立
              <span class="right_text">®</span>（阿达木单抗）不含柠檬酸盐，
              <br />显著减轻注射疼痛
            </div>
          </div>
          <div class="row"
               @mouseover="addAnimate('icon_box', 'animated flash')"
               @mouseout="removeAnimate('icon_box', 'animated flash')">
            <div id="icon_box"
                 class="icon icon_4"></div>
            <!-- <div class="title">高性价比</div> -->
            <div class="des">
              格乐立
              <span class="right_text">®</span>（阿达木单抗）有效降低治疗负担
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 注射视频 -->
    <div class="video_box">
      <div class="video_content">
        <div class="video"
             @click="playVideo">
          <!-- <div class="video_tag"></div> -->
          <video id="injectVideo"
                 src="https://static.adamu.cn/sources/video/inject.mp4"
                 class="video_tag"
                 poster="@/assets/video_bg.png">
            当前浏览器不支持视频播放
          </video>
          <div v-if="!playing"
               class="video_play"></div>
        </div>
        <div class="video_text">
          <div class="video_title">
            <div class="green_line"></div>
            <div>使用方便</div>
            <div class="green_line"></div>
          </div>
          <div class="video_des">皮下注射，预充针剂型</div>
          <a class="video_btn"
             href="injectVideo.html">注射指南</a>
        </div>
      </div>
    </div>
    <!-- 免责声明 -->
    <div class="duty_text"
         id="tips">
      <div class="duty_content">
        <div class="title">
          关于格乐立
          <span class="right_text">®</span>我应该知道的最重要的信息
        </div>
        <div class="content">
          使用前您应该和医生了解格乐立
          <span class="right_text">®</span>的获益和潜在风险。格乐立
          <span class="right_text">®</span>是一种肿瘤坏死因子（TNF）拮抗剂，可降低免疫系统抗感染的能力。如果您有任何类型的感染，在医生认可前不应使用格乐立
          <span class="right_text">®</span>。
          <div class="cicle_content">
            <div class="circle"></div>
            <strong>严重感染可能</strong>
          </div>
          <div class="cicle_content">
            <p>
              <span style="position: relative; top: -0.2rem;"
                    class="circle"></span>
              <strong>
                &nbsp;&nbsp;&nbsp;&nbsp;使用格乐立
                <span class="right_text">®</span>治疗有可能增加严重感染风险，可能导致住院或死亡。这些严重感染包括活动性结核病（包括潜伏性结核感染重新激活）、侵袭性真菌感染、细菌、病毒和由其它条件致病菌导致的感染。
              </strong>
              <span>
                在开始使用格乐立
                <span class="right_text">®</span>之前，医生应该检查您是否患有结核病或潜伏性结核可能，包括仔细检查是否有结核病的症状、体征和做相关的结核筛查检测。即使您的结核筛查检测为阴性，如果医生觉得您有风险，可能建议您先接受结核病药物的治疗。在使用格乐立
                <span class="right_text">®</span>治疗期间和治疗后，您也要定期复查，排除各种严重感染发生的风险。您如果患有慢性或复发性感染，在开始使用格乐立
                <span class="right_text">®</span>治疗前应综合考虑治疗的潜在风险和获益。
              </span>
            </p>
          </div>
          <div class="cicle_content">
            <div class="circle"></div>
            <strong>恶性肿瘤可能</strong>
          </div>
          <div class="cicle_content">
            <p>
              <span class="circle"></span>&nbsp;&nbsp;&nbsp;&nbsp;对于使用TNF拮抗剂(包括格乐立
              <span class="right_text">®</span>)的儿童和成人来说，患淋巴瘤或其他癌症的几率可能会增加。在使用TNF拮抗剂的儿童、青少年和年轻人中，出现一些不常见的恶性肿瘤病例，如罕见的可能导致死亡的肝脾T细胞淋巴瘤。另外，使用TNF拮抗剂（包括格乐立
              <span class="right_text">®</span>）过程中，患基底细胞癌和鳞状细胞癌的几率可能会增加，但是经治疗这些疾病通常不会危及生命。如果您有肿块（包括良性或恶性肿瘤）或未愈合伤口请告诉医生。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 安全性悬浮 -->
    <div class="float_content"
         v-if="showFloat">
      <float class="float_box"></float>
    </div>
    <!-- 底部 -->
    <footer-bar></footer-bar>
    <!-- 视频弹窗 -->
    <div class="video"
         v-if="showVideo">
      <video-mask @closeVideo="closeVideo"></video-mask>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar.vue";
import footerBar from "@/components/footerBar.vue";
import videoMask from "@/components/videoMask.vue";
import float from "@/components/float.vue";
import { addAnimate, removeAnimate } from "@/util.js";
// import { getDoctorList } from "@/server.js";
export default {
  name: "app",
  metaInfo: {
    title: "格乐立阿达木单抗注射液—百奥泰生物制药股份有限公司",
    meta: [
      {
        name: "keywords",
        content:
          "格乐立,格乐立价格,格乐立医药代表,阿达木单抗注射液,中国首个国产阿达木单抗",
      },
      {
        name: "description",
        content:
          "格乐立，焕生命活力。是中国首个获批的国产阿达木单抗，关键质量属性、临床疗效、安全性与原研药物高度一致。格乐立®在国内已获批强直性脊柱炎、类风湿关节炎、银屑病、克罗恩病、儿童克罗恩病、葡萄膜炎、儿童斑块状银屑病、多关节型幼年特发性关节炎8个适应症。",
      },
    ],
  },
  data () {
    return {
      playing: false,
      showVideo: false,
      showFloat: true,
    };
  },
  components: {
    navBar,
    footerBar,
    videoMask,
    float,
  },
  computed: {
    addAnimate () {
      return addAnimate;
    },
    removeAnimate () {
      return removeAnimate;
    },
  },
  mounted () {
    var banner = document.querySelector(".duty_text");
    document.addEventListener("scroll", () => {
      var scope = (banner.offsetHeight * (100 - 80)) / 100; //广告栏可出线范围大小
      var cH = document.documentElement.clientHeight; //当前页面可见区域高度
      var bY = banner.getBoundingClientRect().bottom; //广告栏底部到可见区域顶端的高度
      // 只要滚过了banner区，就不再显示，防止把底部文案遮挡
      if (bY > cH + scope) {
        this.showFloat = true;
      } else {
        this.showFloat = false;
      }
    });
  },
  methods: {
    closeVideo () {
      this.showVideo = false;
    },
    playVideo () {
      this.showVideo = true;
      // var video = document.getElementById("injectVideo");
      // this.playing = true;
      // video.play();
    },
  },
};
</script>

<style lang="less">
// 初始化菜单样式
.init_nav {
  .noblock {
    font-weight: normal !important;
  }
  .nav_pc .nav_title:hover {
    color: #00478c;
  }
  .nav_pc .nav_title {
    color: #ffffff;
  }
}
.right_text {
  font-size: 1rem;
  position: relative;
  top: -1.1rem;
  left: -0.6rem;
}
.banner {
  width: 100%;
  height: 37rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .title {
    font-size: 2rem;

    font-weight: 500;
    color: #ffffff;
    line-height: 2.95rem;

    text-align: center;
    margin-bottom: 2rem;
  }

  .des {
    font-size: 1.4rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 2.5rem;

    width: 65%;
    text-align: center;
    box-sizing: border-box;
    padding: 0 6rem;
    margin: 0.5rem 0;
    .right_text {
      font-size: 0.8rem;
      top: -0.8rem;
      left: -0rem;
    }
  }
  .banner_1 {
    .des {
      .right_text {
        font-size: 0.8rem;
        top: -0.8rem;
        left: 0;
      }
    }
  }
  .des2 {
    font-size: 2rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 2.95rem;
    .right_text {
      font-size: 1rem;
      position: relative;
      top: -1.1rem;
      left: -0.6rem;
    }
  }
  .btn:hover {
    background: white;
    border-color: transparent;
    color: #999999;
  }
  .btn {
    .right_point {
      margin-left: 0.5rem;
      display: inline-flex;
      width: 2rem;
      height: 2rem;
      background-size: 100% 100%;
      background-image: url("../../assets/right_point.png");
    }
    margin-top: 2.8rem;
    padding: 0.3rem 2.5rem;
    border-radius: 0.2rem;
    border: 0.1rem solid #ffffff;
    text-align: center;

    font-size: 1rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 2.1rem;
    cursor: pointer;
  }
  .btn2 {
    margin-top: 2rem;
    font-size: 1rem;
    padding: 0.4rem 0.9rem;
    .small {
      font-size: 1rem;
      // color: rgb(0, 71, 140);
    }
  }
}
.banner_1 {
  background-image: url("../../assets/index_bg_1.png");
}
.banner_2 {
  background-image: url("../../assets/index_bg_2.png");
}
.reg_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4.75rem auto;
  .reg_content,
  .float_content .float_box {
    width: 65%;
  }
  .right_text {
    font-size: 0.8rem;
    top: -0.5rem;
    left: -0.3rem;
  }
  .col {
    width: 100%;
    display: flex;
    flex-direction: row;
    .row {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 5rem 1rem;
      .icon_1 {
        background-image: url(../../assets/reg_icon_1.png);
      }
      .icon_2 {
        background-image: url(../../assets/reg_icon_2.png);
      }
      .icon_3 {
        background-image: url(../../assets/reg_icon_3.png);
      }
      .icon_4 {
        background-image: url(../../assets/reg_icon_4.png);
      }
      .icon {
        width: 4.8rem;
        height: 4.15rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      .title {
        font-size: 1.4rem;

        font-weight: 500;
        color: #333333;
        line-height: 2.5rem;
      }
      .des {
        text-align: center;
        font-size: 0.93rem;

        font-weight: 400;
        color: #3f3f3f;
        line-height: 1.65rem;
        margin: 0.85rem auto 1.2rem;
      }
    }
  }
  .col1 {
    border-bottom: 1px solid #e1e1e1;
  }
  .row1 {
    border-right: 1px solid #e1e1e1;
  }
}
.float_content {
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  bottom: 0;
  cursor: default;
  height: auto;
  left: 0;
  margin: auto;
  padding: 0;
  position: fixed;
  right: 0;
  transition: height 0.3s;
  z-index: 9999999;
}
.duty_text {
  display: flex;
  align-items: center;
  justify-content: center;
  .duty_content {
    width: 65%;
    padding: 5rem 0;
  }
  .title {
    font-size: 1.4rem;

    font-weight: 500;
    color: #3554aa;
    line-height: 2.5rem;
    .right_text {
      top: -0.7rem;
      left: -0.4rem;
    }
  }
  .content {
    font-size: 0.93rem;

    font-weight: 400;
    color: #3f3f3f;
    line-height: 2rem;
    .right_text {
      font-size: 0.7rem;
      top: -0.5rem;
      left: -0.2rem;
    }
    .cicle_content {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;

      p {
        margin: 0;
      }

      .circle {
        display: inline-flex;
        width: 5px;
        height: 5px;
        background: #3554aa;
        border-radius: 100%;
        margin-right: 0.5rem;
      }
    }
  }
}
.video_box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets/inject_bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 25rem;
  .video_content {
    width: 65%;
    display: flex;
    justify-content: space-between;
  }
  .video_text {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: 3rem;
    .video_title {
      display: flex;
      align-items: center;

      font-size: 1.6rem;

      font-weight: 500;
      color: #333333;
      line-height: 2.5rem;
      .green_line {
        width: 4.45rem;
        height: 0.05rem;
        background: #333333;
        margin: 0 0.5rem;
      }
    }
    .video_des {
      margin-top: 0.45rem;
      font-size: 1.6rem;

      font-weight: 400;
      color: #1b355e;
      line-height: 2.5rem;
    }
    .video_btn {
      margin-top: 0.9rem;
      // padding: 1rem 5.95rem;
      width: 18rem;
      height: 4.15rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #3554aa;
      border-radius: 0.4rem;
      cursor: pointer;

      border: 1px solid #eee;

      font-size: 1.3rem;

      font-weight: 400;
      color: #ffffff;
      line-height: 2.1rem;
    }
  }
  .video {
    width: 34rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .video_tag {
      width: 100%;
      object-fit: fill;
    }
    .video_play {
      width: 5.55rem;
      height: 5.55rem;
      position: absolute;
      z-index: 999;
      background: url("../../assets/reg_icon_play.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 1600px) {
  .video_box {
    .video_content {
      width: 55%;
    }
  }
  .reg_box .reg_content,
  .float_content,
  .duty_text .duty_content {
    width: 55%;
  }
  .banner {
    .title,
    .des {
      width: 55%;
    }
  }
}
@media only screen and (max-width: 768px) {
  .video_box {
    .video_content {
      width: 90%;
    }
  }
  .reg_box .reg_content,
  .float_content,
  .duty_text .duty_content {
    width: 90%;
  }
}
@media only screen and (max-width: 480px) {
  .banner {
    background-size: cover;
    .title {
      font-size: 1.7rem;
    }
    .des {
      padding: 0;
    }
  }
  .reg_box {
    .col {
      .row {
        .title {
          font-size: 1.5rem;
        }
      }
    }
    .reg_content,
    .float_content {
      width: 100%;
    }
  }
  .video_box {
    background-size: cover;

    .video_text {
      display: none;
    }
    .video_content {
      width: 90%;
      .video {
        width: 100%;
      }
    }
  }
}
</style>
